import React from "react";
import Explorer from "../components/Explorer";

export default function HomeScreen() {
  return (
    <div>
      <Explorer />
    </div>
  );
}
